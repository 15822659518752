* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loader {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  height: 100vh;
  background: radial-gradient(circle, #f5f5f5 0%, #e8e8e8 100%);
  flex-direction: column;
  display: flex;
}

.top {
  color: #222;
  background-color: #fff;
  flex: none;
}

.middle {
  width: 100%;
  background: linear-gradient(#fff 0%, #c8f2ff 100%);
  flex: auto;
  padding: 0 8px;
}

::-webkit-scrollbar {
  width: 16px;
  background: linear-gradient(#fff 0%, #c8f2ff 100%);
}

::-webkit-scrollbar-thumb {
  background-color: #115f9a;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9f7171;
}

.bottom {
  color: #222;
  background: #c8f2ff;
  flex: none;
  padding: 8px;
}

.mini-button {
  padding: 2px 4px !important;
  line-height: 18px !important;
}

.tf-form-label, .tf-form-labeled-number .label {
  min-width: 140px;
  display: inline-block;
}

.tf-form-input, .tf-form-labeled-number .input {
  width: auto;
  min-width: 280px;
}

.radio label {
  display: block;
}

.StatusBox {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding: 32px 48px;
  overflow-x: auto;
}

.StatusBox::-webkit-scrollbar {
  display: none;
}

.tf-snack {
  opacity: .96;
  width: 49%;
  min-height: 140px;
  z-index: 200;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: #7ed6ff;
  border: 1px solid #ffffff4d;
  border-radius: 4px;
  padding: 0;
  font-size: 18px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  box-shadow: 0 4px 30px #0000001a;
}

.tf-snack .tf-snack-label {
  color: #222;
  padding: 16px;
  font-size: 22px;
  font-weight: bold;
  display: block;
}

.tf-snack .tf-snack-text {
  color: #444;
  padding: 0 16px 16px;
  font-size: 18px;
  display: block;
}

.tf-snack .tf-snack-x {
  cursor: pointer;
  color: #f0506e;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: -2px;
  right: 10px;
}

.chat-icon {
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 81% 75%, 81% 87%, 50% 75%, 0% 75%);
  width: 26px;
  height: 22px;
  text-align: center;
  color: #222;
  background-color: #ddd;
  font-size: 12px;
}

.filter-switch {
  cursor: pointer;
  border-radius: 16px;
  margin-left: 4px;
  padding: 4px 8px;
}

.filter-switch:hover {
  background-color: #e2f8ff !important;
}

.chats-counter {
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
  padding: 3px 14px 1px;
  display: inline-block;
}

.chats-counter:hover {
  background-color: #e2f8ff !important;
}

.CustomerMessagesBox {
  max-width: 620px;
  flex-direction: column;
  flex: auto;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.OrderTile {
  width: 480px;
  background: #f8f8f8;
  border: 1px solid #aaa;
  margin: 16px;
  padding: 12px;
  display: inline-block;
  position: relative;
}

.LabeledTextInput .label, .tf-form-labeled-number .label {
  width: 220px;
  display: inline-block;
}

.LabeledTextInput .input, .tf-form-labeled-number .input {
  width: auto;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  text-align: left;
  color: #222;
  text-align: left;
  z-index: 1;
  width: 500px;
  background-color: #f8f8f8;
  border: 1px solid #0076dc;
  border-radius: 3px;
  padding: 12px;
  font-size: 12px;
  position: absolute;
  bottom: 100%;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

@media screen and (max-width: 700px) {
  .OrderTile {
    width: 100%;
    margin: 8px auto;
  }
}

/*# sourceMappingURL=index.637c41f2.css.map */
