* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: radial-gradient(circle, rgba(245, 245, 245, 1) 0%, rgba(232, 232, 232, 1) 100%);
}

.top {
    flex: 0 0 auto;
    color: #222;
    background-color: #fff;
}

.middle {
    width: 100%;
    flex: 1 1 auto;
    padding: 0 8px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 242, 255, 1) 100%);
}

::-webkit-scrollbar {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 242, 255, 1) 100%);
    width: 16px;
}

::-webkit-scrollbar-thumb {
    background-color: #115f9a;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #9f7171;
}

.bottom {
    flex: 0 0 auto;
    color: #222;
    padding: 8px;
    background: #c8f2ff;
}


.mini-button {
    padding: 2px 4px !important;
    line-height: 18px !important;
}

.tf-form-label,
.tf-form-labeled-number .label {
    min-width: 140px;
    display: inline-block;
}

.tf-form-input,
.tf-form-labeled-number .input {
    width: auto;
    min-width: 280px;
}

.radio label {
    display: block;
}

.StatusBox {
    white-space: nowrap;
    overflow-x: auto;
    padding: 32px 48px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.StatusBox::-webkit-scrollbar {
    display: none;
}

.tf-snack {
    position: fixed;
    opacity: 0.96;
    bottom: 8px;
    right: 8px;
    border: 1px solid #f2f2f2;
    background: #7ed6ff;
    font-size: 18px;
    width: 49%;
    min-height: 140px;
    padding: 0;
    z-index: 200;
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.tf-snack .tf-snack-label {
    padding: 16px;
    display: block;
    color: #222;
    font-size: 22px;
    font-weight: bold;
}

.tf-snack .tf-snack-text {
    display: block;
    padding: 0 16px 16px;
    font-size: 18px;
    color: #444;
}

.tf-snack .tf-snack-x {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -2px;
    font-size: 24px;
    color: #f0506e;
    font-weight: bold;
}

.chat-icon {
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 81% 75%, 81% 87%, 50% 75%, 0% 75%);
    background-color: #ddd;
    width: 26px;
    height: 22px;
    text-align: center;
    font-size: 12px;
    color: #222;
}

.filter-switch {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 16px;
    margin-left: 4px;
}

.filter-switch:hover {
    background-color: #e2f8ff !important;
}

.chats-counter {
    /* background-color: #9f7171; */
    display: inline-block;
    padding: 3px 14px 1px;
    text-align: center;
    cursor: pointer;
    /* width: 42px; */
    border-radius: 16px;
}

.chats-counter:hover {
    background-color: #e2f8ff !important;
}

.CustomerMessagesBox {
    max-width: 620px;
    padding-right: 32px;
    margin: 0 auto;
    overflow-y: auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-x: hidden;
    padding-top: 32px;
    padding-bottom: 32px;
}

.OrderTile {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    width: 480px;
    padding: 12px;
    margin: 16px;
    background: #f8f8f8;
}

.LabeledTextInput .label,
.tf-form-labeled-number .label {
    display: inline-block;
    width: 220px;
}

.LabeledTextInput .input,
.tf-form-labeled-number .input {
    width: auto;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    /* width: 80%; */
    background-color: #f8f8f8;
    border: 1px solid rgb(0, 118, 220);
    text-align: left;
    color: #222;
    font-size: 12px;
    text-align: left;
    padding: 12px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    /* white-space: nowrap; */
    width: 500px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

@media screen and (max-width: 700px) {

    .OrderTile {
        width: 100%;
        margin: 8px auto;
    }
}